import { FC, useState } from 'react'
import { useRouter } from 'next/router'
import s from './Filter.module.css'
import { Directory } from '@framework/types/directory'

interface FilterProps{
  departaments:Array<Directory>
}
export const Filter:FC<FilterProps> = ( { departaments: departamentos } ) => {
  const router = useRouter()
  const [provincia, setProvincia] = useState([]);
  const [distrito, setDistrito] = useState([]);
  const [depValue, setDepValue] = useState("");
  const [proValue, setProValue] = useState("");
  const [disValue, setDisValue] = useState("");
  const [btn, setBtn] = useState(true)
  
  /**
   * trae provincias
   */
  const handlerDepartamento = async({ target }: any)=>{
    const id = target.value
    const url = window.location.origin;
    fetch(`${url}/api/provincia?id=${id}`)
      .then( res => res.json())
      .then( ({data}) => {
        setDepValue(id)
        setProvincia(data)
      })
  }
  /**
   * trae distritos
   */
  const handlerProvincia = async({ target }: any)=>{
    const id = target.value
    const id2 = depValue
    const url = window.location.origin;
    fetch(`${url}/api/distrito?id=${id2}&id2=${id}`)
      .then( res => res.json())
      .then( ({data}) => {
        setProValue(id);
        setBtn(false);
        setDistrito(data)
      })
  }
  const handlerDistrito = ({ target }: any) => {
    const id = target.value
    setDisValue(id);
  }
  /**
   * buscar
   */
  const handlerBuscar = ()=>{
    const dep_name = departamentos.find(item=>item.did === depValue);
    const pro_name:any = provincia.find((item:any)=>item.pid === proValue);
    const dis_name:any = distrito.find((item:any)=>item.did === disValue);
    const path = `/directorio5/${dep_name?.departamento.replace(/ /g,'-')}/${pro_name.provincia.replace(/ /g,'-')}/${dis_name.distrito.replace(/ /g,'-')}`.toLowerCase();
    router.push(path)
  }
  return (
    <div className={`${s.filter_container}`}>
      <div className={`${s.filter_text}`}>
        <p>Cambio de monedas en Perú</p>
      </div>
      <div className={`${s.select_departaments}`}>
        <select id="departaments" className={`${s.select}`} onChange={handlerDepartamento}>
          <option value={-1}>DEPARTAMENTO</option>
          { departamentos.map(({did, departamento}, i) => <option key={i} value={did}>{departamento}</option> ) }
        </select>
      </div>
      <div className={`${s.select_provinces}`}>
        <select id="provinces" className={`${s.select}`} onChange={handlerProvincia}>
          <option value={-1}>PROVINCIA</option>
          { provincia.map(({pid, provincia}, i) => <option key={i} value={pid}>{provincia}</option> ) }
        </select>
      </div>
      <div className={`${s.select_districts}`}>
        <select id="districts" className={`${s.select}`} onChange={handlerDistrito}>
          <option value={-1}>DISTRITO</option>
          { distrito.map(({did, distrito}, i) => <option key={i} value={did}>{distrito}</option> ) }
        </select>
      </div>
      <button id="buscar" className={`${s.buscar} hover:bg-[#027A6F]`} onClick={handlerBuscar} disabled={ btn }>BUSCAR</button>
    </div>
  )
}

export default Filter
