import { FC } from "react"

type Props = {
    fill?: string,
    width?: number,
    height?: number,
}

const Clock: FC<Props> = ({ fill, width, height, ...props }) => (

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 21 22"
        fill="none"
        {...props}
    >
        <g clipPath="url(#clip0_5919_48414)">
            <path d="M14.4375 10.5H9.84375V5.90625C9.84375 5.54531 9.54844 5.25 9.1875 5.25C8.82656 5.25 8.53125 5.54531 8.53125 5.90625V11.1562C8.53125 11.5172 8.82656 11.8125 9.1875 11.8125H14.4375C14.7984 11.8125 15.0938 11.5172 15.0938 11.1562C15.0938 10.7953 14.7984 10.5 14.4375 10.5Z" fill="#000000" />
            <circle cx="10.5" cy="10.5" r="10" stroke="#000000" />
        </g>
        <defs>
            <clipPath id="clip0_5919_48414">
                <rect width="21" height="21" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
export default Clock

