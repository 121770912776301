import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Youtube : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 22 16"
        {...props}
    >
        <path d="M21.201 2.50514C20.9499 1.52686 20.2224 0.754286 19.2914 0.489143C17.5982 0 10.8214 0 10.8214 0C10.8214 0 4.04881 0 2.35567 0.470857C1.44631 0.736 0.697175 1.52686 0.446019 2.50514C0 4.29257 0 8 0 8C0 8 0 11.7257 0.446019 13.4949C0.697175 14.4731 1.42466 15.2457 2.35567 15.5109C4.06613 16 10.8214 16 10.8214 16C10.8214 16 17.5982 16 19.2914 15.5291C20.2181 15.264 20.9499 14.496 21.201 13.5131C21.6471 11.7257 21.6471 8.01829 21.6471 8.01829C21.6471 8.01829 21.6644 4.29257 21.201 2.50514ZM8.66488 11.424V4.576L14.2986 8L8.66488 11.424Z" fill={fill}/>
    </svg>     

)
export default Youtube

