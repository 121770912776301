import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Facebook : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 9 16"
        {...props}
    >
        <path d="M6.11303 3.051H8.22347V0H5.73096V0.0177388C2.71993 0.124169 2.10135 1.77384 2.03768 3.50333V5.02883H0V8.00887H2.05587V16H5.13967V8.00887H7.67767L8.16889 5.02883H5.15787V4.10643C5.14877 3.97339 5.15787 3.84036 5.20335 3.71619C5.24884 3.59202 5.31251 3.47672 5.40348 3.37916C5.49444 3.2816 5.60361 3.20177 5.72186 3.13969C5.84922 3.08647 5.97658 3.05987 6.11303 3.051Z" fill={fill}/>
    </svg>
)
export default Facebook
