export interface CustomWindow extends Window{
  google: any;
  googletag: any,
  ezstandalone: any,
  dataGtm:any,
  gptadslots: any,
  customConfigObject: any,
  inView: any,
  apstag: any,
  stpd: any,
  w2g: any,
  w2gInit: any,
}

export interface page {
  pageView: PageView,
}



export enum PageView {
  HOME,
  CALCULAR,
  HISTORIAL,
  DIRECTORIO,
  DIRECTORIO_DETALLE,
  DIRECTORIO_DETALLE_CATEGORIA,
  DIRECTORIO_DISTRITO,
  DIRECTORIO_ID,
  CAMBIO_ONLINE,
  LANDING,
  DIGITALIZA,
  NOT_FOUND
}

export interface LandingBody {
  price: {
    id: string;
    title: string;
    data: object;
  };
  url: string;
  button: {
    text: string;
    color: string;
    background: string;
  };
  company: {
    name: string;
    text: string;
  };
  cupon?: string;
  validezCupon?: string;
  schedule?: {
    'M_F': string;
    Saturdays: string;
    Sundays: string;
  };
}
