import { FC, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import DownArrow from "@components/ui/icons/DownArrow";
import Menu from "@components/ui/icons/Menu";
import { IMenu } from "@framework/types/menu";
import Instagram from "@components/ui/icons/Instagram";
import Twitter from "@components/ui/icons/Twitter";
import Youtube from "@components/ui/icons/Youtube";
import Facebook from "@components/ui/icons/Facebook";
import Linkedin from "@components/ui/icons/Linkedin";
import s from "./Navbar.module.css";
import { OnlineExchange } from "@framework/types/home";
import Tiktok from "@components/ui/icons/Tiktok";
import AdsSlot from "@components/AdsSlot/AdsSlot";
import classNames from "classnames";
import Whatsapp from "@components/ui/icons/Whatsapp";
import ArrowGreen from "@components/ui/icons/ArrowGreen";

interface navigation {
  menu: Array<IMenu>;
  onlineExchangeHouses: OnlineExchange[] | null;
  className?: string
}
const Navbar: FC<navigation> = ({ menu, onlineExchangeHouses, className }) => {
  const [expanded, setExpanded] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const router = useRouter();
  
  const handleClick = () => {
    setOpenNav(!openNav);
  };


  // ordenar menu por su propiedad order
  menu?.sort((a, b) => a.order - b.order);

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const formatSite = (site: string) => {
		const regex = /\?.*/;
		const urlModificada = site.replace(regex, '?utm_campaign=ticker');
		const cleanedURL = urlModificada.replace(/\r\n/g, '');

		return cleanedURL;
	};

  const handleRouteChangeComplete = () => {
      setOpenNav(false);
  };

  const newData = onlineExchangeHouses?.map((item: any) => {
		return {
			...item,
			site: formatSite(item.site),
		};
	});
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
        router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [ expanded, router.events]);

  console.log('')

  return (
    <div className={`${className}`}>
      {!scrolled ? (
        <>
          <div className="flex justify-between h-[50px] md:h-[78px] w-screen md:w-full bg-[#FFDF4C] md:bg-primary2 px-[13px] md:px-0 drop-shadow-lg">
            <div className="md:hidden w-full my-1.5 md:ml-4">
              <Image
                height="37"
                width="69"
                className="cursor-pointer"
                src="/icons/ced-logo-black.svg"
                alt="Logo de cuánto está el dólar"
                onClick={() => router.push("/")}
              />
            </div>
            <div className="hidden md:flex w-full max-w-[95px] md:my-auto text-center md:ml-4">
              <Image
                height="51"
                width="95"
                className="cursor-pointer"
                src="/icons/ced-logo-black.svg"
                alt="Logo de cuánto está el dólar"
                onClick={() => router.push("/")}
              />
            </div>
            <nav className="hidden md:flex align-middle items-center justify-around w-full ">
              <ul className="flex ">
                {menu.slice(0,9).map(({ id, name, path }) => {
                  return (
                    <Link key={id} href={path}>
                      <a className="font-extrabold cursor-pointer mx-1.5 md:text-base">{name}</a>
                    </Link>
                  );
                })}
              </ul>
              {/* <a
                href="https://www.youtube.com/channel/UCjkqalXiCb8YNsQsZlYmLlQ"
                target="_blank"
                rel="noreferrer nofollow"
                className=""
                aria-label="Youtube"
              >
                <Youtube width={20} height={20} fill={"#262103"} />
              </a> */}
              <div className="flex pr-2 max-w-[120px]">
                <div className="mx-1 my-auto" onClick={() => setExpanded(!expanded)}>
                  <div className={`cursor-pointer ${!expanded && "rotate-180"}`}>
                    <DownArrow fill="#FFDF4C" width={20} height={20} />
                  </div>
                </div>
              </div>
            </nav>
            {/* MEJORAR ESTE MENU MOVIL */}
              <button onClick={handleClick} className={` md:hidden flex flex-col justify-center items-center mx-3 h-7 my-auto focus:outline-none focus:ring-0 active:bg-transparent'`}  aria-label="Boton menú">
                  <span className={`bg-black block transition-all duration-300 ease-out h-[3px] w-[24px] rounded-lg ${openNav ? 'rotate-45 translate-y-0.5' : '-translate-y-0.5'}`} >
                  </span>
                  <span className={`bg-black block transition-all duration-300 ease-out h-[3px] w-[24px] rounded-lg my-[2px] ${openNav ? 'opacity-0' : 'opacity-100'}`} >
                  </span>
                  <span className={`bg-black block transition-all duration-300 ease-out h-[3px] w-[24px] rounded-lg ${openNav ? '-rotate-45 -translate-y-2' : 'translate-y-0.5'}`} >
                  </span>  
              </button>
          </div>
          <div
            className={`hidden md:grid  grid-cols-7 items-center gap-2.5 w-full h-[60px] shadow-[0_2px_4px_rgba(0,0,0,0.15)] bg-[#ffffff80] font-bold ${
              expanded ? "banner show" : "content"
            }`}
          >
            <div className="flex justify-center items-center">
              <Youtube width={16.41} height={16} fill={"#262103"} />
              <a
                href="https://www.youtube.com/channel/UCjkqalXiCb8YNsQsZlYmLlQ"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="Youtube"
              >
                Youtube
              </a>
            </div>
            <div className="flex justify-center items-center">
              <Whatsapp width={16.41} height={16} fill={"#262103"} />
              <a
                href="https://whatsapp.com/channel/0029VacwBIPBKfi0LVgfgN0M"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="whatsapp"
              >
                Whatsapp
              </a>
            </div>
            <div className="flex justify-center items-center ">
              <Instagram width={16.41} height={16} fill={"#262103"} />
              <a
                href="https://www.instagram.com/cuantoestaeldolar/"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="Instagram"
              >
                Instagram
              </a>
            </div>
            <div className="flex justify-center items-center">
              <Linkedin width={16.41} height={16} fill={"#262103"} />
              <a
                href="https://www.linkedin.com/company/cuantoestaeldolar/"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="Linkedin"
              >
                Linkedin
              </a>
            </div>
            <div className="flex justify-center items-center ">
              <Twitter width={18.91} height={16} fill={"#262103"} />
              <a href="https://twitter.com/cuantoestadolar" target="_blank" rel="noreferrer nofollow" className="ml-4"  aria-label="Twitter">
                Twitter
              </a>
            </div>
            <div className="flex justify-center items-center">
              <Tiktok width={20} height={20} fill={"#23272A"} />
              <a
                href="https://www.tiktok.com/@cuantoestaeldolar"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="Tiktok"
              >
                Tiktok
              </a>
            </div>
            <div className="flex justify-center items-center">
              <Facebook width={16.41} height={16} fill={"#262103"} />
              <a
                href="https://www.facebook.com/cuantoestaeldolar"
                target="_blank"
                rel="noreferrer nofollow"
                className="ml-4"
                aria-label="Facebook"
              >
                Facebook
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className="justify-between h-[78px] md:top-0 md:w-[970px] bg-white flex fixed shadow-lg z-30 px-4 md:px-0">
          <div className="hidden md:flex w-full max-w-[95px] md:my-auto text-center md:ml-4">
            <Image
              height="51"
              width="96"
              className="cursor-pointer"
              src="/icons/ced-logo-yellow.svg"
              alt="Logo de cuánto está el dólar"
              onClick={() => router.push("/")}
            />
          </div>
          {!openNav ? (
						<div className='flex w-full py-2 mr-2'>
							<div className='nav-data overflow-hidden '>
              <div className='target flex divide-x '>
									{newData?.map(
										(
											{ id, img, rates, site, height, url_ticker },
											index
										) => (
											<a
												key={index}
												href={url_ticker}
												target='_blank'
												rel='noreferrer'
												className='flex mx-2 md:mx-4 '>
                          <div className="block mx-4">
                            <div className='w-24 md:max-w-32 md:w-32 flex justify-center align-middle h-7 my-auto'>
                              <Image
                                src={img}
                                alt='Logo de casa de cambio'
                                width={100}
                                height={height}
                              />
                            </div>
                            <div className="w-full flex mt-2">
                              <button className={`${s.shadow_button} mx-auto w-[100px] font-medium text-black bg-white border border-[#D8D8D8] text-[10px] rounded-full px-4 py-1`} >Cambiar</button>
                            </div>
                          </div>
												<div className='mx-2 my-auto'>
													<p className='text-secondary'>
														Compra
													</p>
													<p>{parseFloat(rates.buy.cost).toFixed(3)}</p>
												</div>
												<div className='mx-2 my-auto'>
													<p className='text-secondary'>
														Venta
													</p>
													<p>{parseFloat(rates.sale.cost).toFixed(3)}</p>
												</div>
											</a>
										)
									)}
								</div>
							</div>

							<div
								className='hidden md:block cursor-pointer my-auto'
								onClick={() => setOpenNav(true)}>
								<div className='w-10 h-10 bg-primary rounded-full text-center grid justify-center hover:drop-shadow-md'>
									<div className='pt-2'>
										<Menu
											width={21}
											height={16}
										/>
									</div>
									<span className='text-[7px]'>MENÚ</span>
								</div>
							</div>
						</div>
					) : (
						<div className='flex w-full py-2 mr-2'>
							<nav className='hidden md:flex align-middle items-center w-full justify-around '>
								<ul className='flex'>
									{menu?.map(({ id, name, path, img, width, height, altImg }) => {
										return (
											<Link
												key={id}
												href={path}>
												<a className='font-extrabold cursor-pointer mx-2 md:text-lg lg:text-lg'>
													{name}
												</a>
											</Link>
										);
									})}
								</ul>
							</nav>
							<div
								className='cursor-pointer my-auto'
								onClick={() => setOpenNav(false)}>
								<div className='w-10 h-10 bg-primary rounded-full text-center grid justify-center items-center hover:drop-shadow-md'>
									<Image
										src={'/icons/close.svg'}
										width='20'
										height='20'
										alt='icon cerrar'
									/>
								</div>
							</div>
						</div>
					)}
        </div>
          
      )}
      {openNav && (
        <div >
          <nav className="block absolute bg-white w-screen z-50 shadow-sm md:hidden align-middle items-center justify-between md:max-w-4xl mx-auto">
            <ul className="grid divide-y divide-lgray">
              {menu.map(({ id, name, path, img, width, height, altImg }, index) => {
                return (
                  <div className="flex justify-between" key={index}>
                    <Link key={id} href={path}>
                    <div className="flex justify-between w-full">
                      <div className="flex">
                        {img && (
                          <div className="w-11 flex justify-center items-center ml-2">
                            <Image src={img} width={width} height={height} alt={altImg} />
                          </div>
                        )}
                          <p className="p-5 md:p-0 font-bold cursor-pointer text-lg">{name}</p>
                      </div>
                      <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                    </div>
                    </Link>
                  </div>
                );
              })}
            </ul>
            <ul className="grid divide-y divide-lgray">
              <li className={`${s.lista}`}>
                <a
                  href={"https://www.youtube.com/channel/UCjkqalXiCb8YNsQsZlYmLlQ"}
                  target="_blank"
                  rel="noreferrer nofollow"
                  aria-label="Youtube"
                  className="w-full"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                      <Youtube width={21.65} height={16} fill={"#262103"} />
                      <p className="ml-8">Youtube</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://whatsapp.com/channel/0029VacwBIPBKfi0LVgfgN0M"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                  aria-label="whatsapp"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                      <Whatsapp width={16.41} height={16} fill={"#262103"} />
                    <p className="ml-8">Whatsapp</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://www.instagram.com/cuantoestaeldolar/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                      <Instagram width={16.41} height={16} fill={"#262103"} />
                    <p className="ml-8">Instagram</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                  
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://www.linkedin.com/company/cuantoestaeldolar/"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                  aria-label="Linkedin"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                    <Linkedin width={16.41} height={16} fill={"#262103"} />
                    <p className="ml-8">Linkedin</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                  
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://twitter.com/cuantoestadolar"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                  aria-label="Twitter"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                    <Twitter width={18.91} height={16} fill={"#262103"} />
                    <p className="ml-8">Twitter</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://www.facebook.com/cuantoestaeldolar"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                  aria-label="Facebook"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                      <Facebook width={9} height={16} fill={"#262103"} />
                    <p className="ml-8">Facebook</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                      </div>
                  </div>
                  
                </a>
              </li>
              <li className={`${s.lista}`}>
                <a
                  href="https://www.tiktok.com/@cuantoestaeldolar"
                  target="_blank"
                  rel="noreferrer nofollow"
                  className="w-full"
                  aria-label="Tiktok"
                >
                  <div className="flex justify-between w-full">
                    <div className="flex ml-5 items-center">
                      <Tiktok width={20} height={20} fill={"#23272A"} />
                    <p className="ml-8">Tiktok</p>
                    </div>
                    <div className="flex justify-center items-center mx-3">
                        <ArrowGreen width={20} height={20} />
                    </div>
                  </div>
                  
                </a>
              </li>
            </ul>
          </nav>
          <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={() => setOpenNav(false)}>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
