import Facebook from '@components/ui/icons/Facebook';
import Instagram from '@components/ui/icons/Instagram';
import Linkedin from '@components/ui/icons/Linkedin';
import Twitter from '@components/ui/icons/Twitter';
import Youtube from '@components/ui/icons/Youtube';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';
import s from "./Footer.module.css";
import Mail from '@components/ui/icons/Mail';

interface FooterProps {
  
}
const NewFooter:FC<FooterProps> = ()=>{
  let iconStyles = { color: "white", fontSize: "1.5em" };
  const [suscribe, setSuscribe] = useState(false);
  const [label, setLabel] = useState('Entérate sobre el cambio del dólar');
  const [formData, setFormData] = useState('')
  const handleClick = () => {
    setSuscribe(false);
  };
  const inputHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setFormData(email);
  };
  const subscribeClick = (event:any) => {
    if(label == 'Entérate sobre el cambio del dólar'){
      setLabel('')
    }else if(formData !== ''){
      saveEmail()
    }
  }
  const susbcribe = () => 
  (
    <div className={`${s.subscription_content_title}`} >
      {label == ''? 
      <input type="text" placeholder='Correo electrónico' value={formData} onChange={inputHandle} className='bg-third text-gray2 placeholder:text-gray2 border-solid border-b w-[200px] md:w-[380px] md:text-lg'/>
      : 
      <p className='md:text-2xl md:leading-7 md:w-[450px]' onClick={subscribeClick}>{label}</p>
      }
    </div>
  )
  const saveEmail = () => {
    fetch(`https://api2.cuantoestaeldolar.pe/Api/subscribed?email=${formData}`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((result) => {
        if(result.status == true){
          setLabel('¡Te has suscrito a noticias sobre el dólar!')
        }
      })
      .catch((err) => console.log('error'))
  }
  return (
    <>
    { suscribe && (
        <div className={`${s.subscription} relative`}>
          <div className={`${s.container}`}>
            <div className={`${s.subscription_content}`}>
              <div className={`${s.icon}`}>
                <Image height="30" width="22" src="/icons/icon_mail.svg" alt="icono correo" />
              </div>
              {susbcribe()}
            </div>
            <div className={`${s.subscription_action}`}>
              <div className={`${s.subscription_action_button}`}>
                <button className={`${label !== '' ? '' : 'mr-2'}`} onClick={subscribeClick}>SUSCRIBIRME</button>
              </div>
              <div className={`${s.subscription_action_close}`}>
                <Image src="/icons/icon_close.svg" alt="icono cerrar" width={20} height={20} onClick={() => handleClick()}/>
              </div>
            </div>
          </div>
        </div>
      ) }
      <div className={`${s.footer} relative`}>
        <div className={`${s.footer_container}`}>
        <div className={`${s.footer_nav_list} ${s.contact} mb-14`}>
              <p className='font-bold'>Contáctanos</p>
              <ul>
              <li className='md:flex hidden mb-[39px]'>
                  <a href="https://www.facebook.com/cuantoestaeldolar" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Facebook de Cuanto esta el dolar.'>
                    <Facebook width={15} height={15} fill='white'/>
                  </a>
                  <a href="https://www.instagram.com/cuantoestaeldolar/" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Instagram de Cuanto esta el dolar'>
                    <Instagram width={15} height={15} fill='white'/>
                  </a>
                  <a href="https://twitter.com/cuantoestadolar" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Twitter de cuanto esta el dolar'>
                    <Twitter width={15} height={15} fill='white'/>
                  </a>
                  <a href='https://www.youtube.com/channel/UCjkqalXiCb8YNsQsZlYmLlQ' className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Pagina de Youtube de Cuanto esta el dolar'>
                    <Youtube width={15} height={15} fill='white'/>
                  </a>
                  <a href="https://www.linkedin.com/company/cuantoestaeldolar/" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Linkedin de Cuanto esta el dolar'>
                  <Linkedin width={15} height={15} fill='white'/>
                  </a>
                </li>
                <li>© Copyright BIG CLICK SAC 2024</li>
                <li className='flex justify-center md:justify-start mt-[19px]'>
                  <Mail width={21} height={15} fill='white'/>
                  <a href="mailto:publicidad@cuantoestaeldolar.pe" className='mx-2'>
                  publicidad@cuantoestaeldolar.pe
                  </a>
                </li>
                <li className='flex justify-center md:hidden'>
                  <a href="https://www.facebook.com/cuantoestaeldolar" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Facebook de Cuanto esta el dolar.'>
                    <Facebook width={25} height={25} fill='white'/>
                  </a>
                  <a href="https://www.instagram.com/cuantoestaeldolar/" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Instagram de Cuanto esta el dolar'>
                    <Instagram width={25} height={25} fill='white'/>
                  </a>
                  <a href="https://twitter.com/cuantoestadolar" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Twitter de cuanto esta el dolar'>
                    <Twitter width={25} height={25} fill='white'/>
                  </a>
                  <a href='https://www.youtube.com/channel/UCjkqalXiCb8YNsQsZlYmLlQ' className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Pagina de Youtube de Cuanto esta el dolar'>
                    <Youtube width={25} height={25} fill='white'/>
                  </a>
                  <a href="https://www.linkedin.com/company/cuantoestaeldolar/" className='mx-1' target="_blank" rel="noreferrer nofollow" aria-label='Página de Linkedin de Cuanto esta el dolar'>
                  <Linkedin width={25} height={25} fill='white'/>
                  </a>
                </li>
              </ul>
            </div>
            <div className={`${s.seccion} mb-14`}>
              <p className='text-white font-bold'>Secciones</p>
              <ul>
                <li>
                  <Link href={'/cambio-de-dolar-online'}>Cambio de dólar online</Link>
                </li>
                <li>
                  <Link href={'/directorio5'}>
                    Casas de Cambio</Link> 
                </li>
                <li>
                  <Link href={'/historial'}>
                    Variación del del dólar</Link> 
                </li>
                <li>
                  <Link href={'/calcular'}>
                    Calcular</Link> 
                </li>
                <li>
                  <a href="https://blog.cuantoestaeldolar.pe/" target="_blank" rel="noreferrer nofollow">Noticias</a>
                </li>
              </ul>
            </div>
            <div className={`${s.seccion}`}>
              <p className='text-white font-bold'>Políticas</p>
              <ul> 
                <li>
                  <Link href={'/term-cond'}>Términos y condiciones</Link> 
                </li>
                <li>
                  <Link href={'/preguntas'}>Preguntas frecuentes</Link> 
                </li>
                <li>
                  <Link href={'/servicios'}>Publicidad</Link> 
                </li>
              </ul>
            </div>
        </div>
      </div>
    </>
  )
}

export default NewFooter