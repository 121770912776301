import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Linkedin : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 17 16"
        {...props}
    >
        <path d="M16.4102 16.0001V10.1317C16.4102 7.26326 15.7804 5.05273 12.3345 5.05273C10.6791 5.05273 9.57247 5.9387 9.11362 6.7808H9.06864V5.32466H5.80273V16.0001H9.20359V10.7106C9.20359 9.31588 9.4735 7.97378 11.2459 7.97378C12.9913 7.97378 13.0183 9.5615 13.0183 10.7983V16.0001H16.4102Z" fill={fill}/>
        <path d="M0.279297 5.31543H3.68016V15.9908H0.279297V5.31543Z" fill={fill}/>
        <path d="M1.97034 0C0.881704 0 0 0.859648 0 1.92105C0 2.98245 0.881704 3.85964 1.97034 3.85964C3.05897 3.85964 3.94068 2.98245 3.94068 1.92105C3.94068 0.859648 3.05897 0 1.97034 0Z" fill={fill}/>
    </svg>
)
export default Linkedin


