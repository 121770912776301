import { FC } from 'react';
import s from "./AdsSlot.module.css";
import cn from 'classnames';

interface AdsSlotProps {
    div: string;
    className?: string;
    variant?: string;
}
declare let window: any

const AdsSlot:FC<AdsSlotProps> = ({ div, className, variant })=>{
    const classNames = cn(
        {
            [s.ads]: variant != 'skin',
            [s.skin]: variant === 'skin',
            [s.top0]: variant === 'top0',
            [s.top]: variant === 'top',
            [s.left]: variant === 'left',
            [s.right]: variant === 'right',
            [s.top_mobile]: variant === 'top_mobile',
        },
        className
    ) 

    return (
        <div id={div} className={`${variant === 'top0' ? '' : variant === 'skin' ? '' : variant === 'anchor' ? '' : variant === 'top_mobile' ? '' : 'my-5' } ${classNames}`}></div>
    )
}

export default AdsSlot