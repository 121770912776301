import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const DownArrow : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 23 23" 
        {...props}
    >
        <circle cx="11.5" cy="11.5" r="11" transform="rotate(-180 11.5 11.5)" stroke="black"/>
        <path d="M17.6663 13.7121C18.1112 13.3282 18.1112 12.7059 17.6663 12.322L11.5 7L5.33366 12.322C4.88878 12.7059 4.88878 13.3282 5.33366 13.7121C5.77853 14.096 6.49982 14.096 6.9447 13.7121L11.5 9.78016L16.0553 13.7121C16.5002 14.096 17.2215 14.096 17.6663 13.7121Z" fill="black"/>
    </svg>
)
export default DownArrow

