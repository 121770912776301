import { format as dateFnsFormat, utcToZonedTime } from 'date-fns-tz';

export const getPeruTime = () => {
  const timeZone = 'America/Lima';
  const now = new Date();
  const peruTime = utcToZonedTime(now, timeZone);
  return peruTime;
};

export const isInTimeRange = (startHour: number, endHour: number): boolean => {
  const peruTime = getPeruTime();
  const currentHour = peruTime.getHours();
  return currentHour >= startHour && currentHour < endHour;
};
