import { CustomWindow } from "@framework/types/site";

declare let window: CustomWindow;
declare let stpd: any;

const dfp = {
  defineSlot: (key: string, size:string, div:string, name:string, inview?:any)=>{
      if( window ){
        let iSstpd = false
        const { googletag } = window;
        if(!googletag) { return }
        const render = () => {
          switch(name){
            case 'top0':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              break;
            case 'skin':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              break;
            // NUEVO
            case '970x250_banner_desktop_1':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
              break;
            case '1000x100_anchor_desktop':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
            break;
            // NUEVO 04/07/23
            case '144x600_skyscraper_left_desktop':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
            break;
            case '144x600_skyscraper_right_desktop':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
            break;
            case '970x90_banner_top_desktop':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
            break;
            case '320x100_anchor_top_mobile':
              googletag.defineSlot(key, eval(size), div).addService(googletag.pubads());
              iSstpd = true
          }
          // if(name === 'skin' || name === 'top0'){
          // const isDiv = document.getElementById(div);
          // if(isDiv){
          //   googletag.display(div)
          // }
          // }
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          if(iSstpd) {
            googletag.pubads().disableInitialLoad();
            googletag.pubads().setTargeting('category', 'finance').setTargeting('audience_interest', 'finance');
            window.stpd = window.stpd || {que: []};
            stpd.que.push((function() {
            stpd.initialize();
            }));
          }
          if(iSstpd) stpd.initializeAdUnit(div);
        }
        const renderRepeatable = () => {
          switch(name){
            case '970x250_banner_lazy_repeatable_desktop':
            dfp.lazyLoadPageSlot(inview)
            break;
          }
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          if(iSstpd) {
            googletag.pubads().disableInitialLoad();
            googletag.pubads().setTargeting('category', 'finance').setTargeting('audience_interest', 'finance');
            window.stpd = window.stpd || {que: []};
            stpd.que.push((function() {
            stpd.initialize();
            }));
          }
          if(iSstpd) stpd.initializeAdUnit(div);
        }
        const renderAds = () => {
          try {
            render();
          } catch (error) {
            // render();
            dfp.removeSlot();
            render();
          } 
          const tryRender = () => {
            try {
              renderRepeatable();
            } catch (error) {
              dfp.removeSlot();
            }
          }
          for (let i = 0; i < 6; i++) {
            tryRender();
          }
        }
        googletag.cmd.push(() => {
          renderAds();
        });
      }
  },
  // defineOutOfPageSlot: (key:string  = '')=>{
  //   const { googletag } = window;
  //   if(!googletag) { return }
  //   googletag.cmd.push(()=>{
  //     let interstitialSlot = googletag.defineOutOfPageSlot(key, googletag.enums.OutOfPageFormat.INTERSTITIAL);
  //     if (interstitialSlot) interstitialSlot.addService(googletag.pubads());
  //     googletag.pubads().enableSingleRequest();
  //     googletag.pubads().disableInitialLoad();
  //     googletag.pubads().setTargeting('category', 'finance').setTargeting('audience_interest', 'finance');
  //     googletag.enableServices();
  //     googletag.display(interstitialSlot);
  //     window.stpd = window.stpd || {que: []};
  //           stpd.que.push((function() {
  //           stpd.initialize();
  //           }));
  //   });
  // },
  lazyLoadPageSlot:(inView:any ) => {
    const sizeDesktop = '[[970,250],[970,90],[728,90],[970,200],[970,120],[950,90],[728,100],[728,250]]'
    const sizeResposive = '[[300,250],[336,336],[336,320],[320,320],[300,300],[336,280],[320,250],[320,336]]'
    const { googletag } = window;
    if(!googletag) { return }
    let randomiser = (Math.random() + 1).toString(36).substring(7);
    const adElement = document.getElementById('cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive');
    if(adElement){
      let adID = adElement.id = "cuantoestaeldolar_pe_970x250_banner_lazy_repeatable_responsive-" + randomiser;
      if(inView) {
        inView(`#${adID}`).once('enter', () => {
          googletag.cmd.push(() => {
            if(window.innerWidth > 1000){
              googletag.defineSlot('/147246189,429892472/cuantoestaeldolar.pe_970x250_banner_lazy_repeatable_desktop', eval(sizeDesktop), adID).addService(googletag.pubads());
            } else {
              googletag.defineSlot('/147246189,429892472/cuantoestaeldolar.pe_336x336_banner_lazy_repeatable_mobile', eval(sizeResposive), adID).addService(googletag.pubads());
            }
          });
          googletag.display(adID);
          stpd.initializeAdUnit(adID);
        }
        );
      }
    }
  },
  removeSlot: ()=>{
    const { googletag } = window
    if(!googletag){
      return
    }
    googletag.cmd.push(()=>{
      console.log('exec destroy')
      googletag.destroySlots()
    })
  },
  refreshSlot: (div:string = '')=>{
    const { googletag } = window
    if(!googletag){
      return
    }
    googletag.cmd.push(()=>{
      googletag.pubads().refresh([div])
    })
  }
}

export default dfp;