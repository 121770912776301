import React, { FC } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image';


interface IArticle{
  id: string;
  nombre: string;
  direccion: string;
  referencia: string;
  sbs: number;
  path_relative?: boolean
}

const Article:FC<IArticle> = ({ id, nombre, direccion, referencia, sbs, path_relative }) => {
  const url = nombre?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().replace(' ','').replace(' ','-').toLowerCase();
  let path = '';
  const { asPath } = useRouter();
  if(path_relative){
    path = asPath.split('/').slice(0, asPath.split('/').length - 1).join("/")
  }else{
    path = asPath;
  }
  return (
    <article className='article'>
      <div className="content_article">
        <h2 className='h2_article'>
          <Link href={`${path}/${url}-${id}`}>
            <a>{nombre} »</a>
          </Link>
        </h2>
        <h3 className='text-2xl font-bold'>Dirección: {direccion}</h3>
        <p className='text-primary'>Referencia: {referencia}</p>
      </div>
      <div className="image_article my-auto">
        <div className='hidden md:flex md:w-[208px] w-[98px]'> 
        {( sbs == 1 ) &&  <Image  src="/img/sbs_large.svg" alt="Logo superintendencia de bancas, seguros y afp"  width={150} height={98} /> }
        </div>
        <div className='flex md:hidden'>
          {( sbs == 1 ) && 
          <div className='block md:w-[208px] w-[98px] mx-3'>
          <h3 className='text-center text-xs text-third2'>Registrada en la SBS.</h3>
          <Image src="/img/sbs.svg" alt="Logo superintendencia de bancas, seguros y afp" width={98} height={98} />
          </div>
          }
        </div>
      </div>
    </article>
  )
}

export default Article;
