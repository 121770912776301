import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Instagram : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 17 16"
        {...props}
    >
        <path d="M9.07118 5.82885C8.51214 5.60028 7.899 5.54753 7.30389 5.66181C6.70879 5.7761 6.16778 6.05742 5.74399 6.4794C5.32021 6.89259 5.02265 7.42885 4.90544 8.00028C4.78822 8.5805 4.85133 9.1783 5.07675 9.72336C5.31119 10.2684 5.69891 10.7343 6.20385 11.0596C6.70878 11.3849 7.29488 11.5607 7.899 11.5607C8.71051 11.5607 9.47693 11.2442 10.054 10.6904C10.6221 10.1365 10.9467 9.3805 10.9467 8.58929C10.9467 8.00028 10.7663 7.42885 10.4327 6.93654C10.1081 6.43544 9.63022 6.05742 9.07118 5.82885Z" fill={fill}/>
        <path d="M12.2899 0H4.12067C1.84844 0 0 1.8022 0 4.01758V11.9824C0 14.1978 1.84844 16 4.12067 16H12.2899C14.5621 16 16.4105 14.1978 16.4105 11.9824V4.01758C16.4015 1.8022 14.5531 0 12.2899 0ZM11.9653 11.2176C11.4333 12 10.6669 12.6066 9.77419 12.967C8.88153 13.3275 7.89871 13.4242 6.95194 13.2396C6.00518 13.0549 5.13957 12.6066 4.45429 11.9385C3.76902 11.2703 3.30916 10.4264 3.11981 9.5033C2.93046 8.58022 3.02964 7.62198 3.39933 6.75165C3.76902 5.88132 4.39118 5.14286 5.19367 4.61539C5.99616 4.0967 6.94292 3.81538 7.90772 3.81538C9.20614 3.81538 10.4414 4.31648 11.3611 5.2044C12.2718 6.09231 12.7858 7.3055 12.7858 8.57143C12.7858 9.52088 12.4973 10.4352 11.9653 11.2176ZM13.1465 4.0967C12.7858 4.0967 12.4973 3.81538 12.4973 3.46374C12.4973 3.11209 12.7858 2.83077 13.1465 2.83077C13.5071 2.83077 13.7957 3.11209 13.7957 3.46374C13.8047 3.81538 13.5071 4.0967 13.1465 4.0967Z" fill={fill}/>
    </svg>      

)
export default Instagram

