import Clock from '@components/ui/icons/Clock';
import { dateSpanishShort } from '@framework/lib/dateFormat';
import React, { FC, useEffect, useState } from 'react'
import s from "./BreadCrumb.module.css";

interface IBreadCrumb{
  section: string
  fill?: string
}
const BreadCrumb:FC<IBreadCrumb> = ({ section, fill }) => {
  const [dateLocal, setdateLocal] = useState('')
  useEffect(() => {
    setdateLocal(dateSpanishShort(new Date));
  }, [dateLocal])
  return (
    <div className={`${(section == 'En vivo' || section == 'Calcular' || section =='Servicios / Diseño de marcas para fintech' || section =='Servicios / Audiencias digitales' || section == 'Error') ? 'text-white' : 'text-primary' } ${s.breadcrumb_content}`}>
      <div className={`${s.breadcrumb_nav}`}>
        Inicio / { section }
      </div>
      <div className='block md:flex'>
        <div className='absolute md:hidden md:mx-1'>
          <Clock width={21} height={21} fill={fill ? fill : '#ffffff'}/>
        </div>
        <div className={`${s.breadcrumb_hours}`}>
        {dateLocal}  
        </div>
        <div className={`${(section == 'Casas de cambio online' || section == 'Calcular'  || section == 'Historial del dólar') ? 'hidden md:flex md:mx-1' : 'hidden' }`} >|</div>
        <div className={`${(section == 'Casas de cambio online' || section == 'Calcular'  || section == 'Historial del dólar') ? '' : 'hidden' } ${s.breadcrumb_money}`}>
          Dólares a soles / soles a dólares      
        </div>
      </div>
    </div>
  )
}

export default BreadCrumb;