/** @format */

import { FC } from 'react';

type Props = {
	fill?: string;
	width?: number;
	height?: number;
};

const Twitter: FC<Props> = ({ fill, width, height, ...props }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={width}
		height={height}
		fill={fill}
		viewBox='0 0 19 16'
		{...props}>
		<path
			d='M2.0365 1L7.82782 8.72191L2 15H3.31171L8.41408 9.50332L12.5365 15H17L10.8827 6.84383L16.3073 1H14.9956L10.2967 6.06218L6.5 1H2.0365ZM3.96543 1.96364H6.01594L15.0708 14.0366H13.0203L3.96543 1.96364Z'
			fill={fill}
		/>
	</svg>
);
export default Twitter;
