import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Mail : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 21 15"
        {...props}
    >
    <path d="M10.2343 8.6715L19.9093 0.572055C19.5586 0.219582 19.0683 0 18.5248 0H1.93087C1.47853 0 1.06243 0.152282 0.733398 0.407225L10.2343 8.6715Z" fill="white"/>
    <path d="M10.5698 9.74283C10.471 9.82553 10.3494 9.86659 10.2273 9.86659C10.1022 9.86659 9.97717 9.82325 9.87723 9.73656L0.113961 1.24414C0.0403248 1.44376 0 1.65878 0 1.8835V13.1164C0 14.1561 0.864353 14.9997 1.93033 14.9997H18.5248C19.5908 14.9997 20.4552 14.1561 20.4552 13.1158V1.8835C20.4552 1.75232 20.4411 1.62456 20.4154 1.5008L10.5698 9.74283Z" fill="white"/>
    </svg>

)
export default Mail

