import { FC } from "react"

type Props = {
  fill?: string,
  width?: number,
  height?: number,
}

const Menu : FC<Props> = ({fill, width , height, ...props}) => (

    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={width} 
        height={height} 
        fill= {fill} 
        viewBox="0 0 21 17"
        {...props}
    >
        <rect x="0.232422" y="0.441895" width="20.4651" height="2.7907" rx="1.39535" fill="black"/>
        <rect x="0.232422" y="6.95361" width="20.4651" height="2.7907" rx="1.39535" fill="black"/>
        <rect x="0.232422" y="13.4651" width="20.4651" height="2.7907" rx="1.39535" fill="black"/>
    </svg>

)
export default Menu

